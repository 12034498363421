<template>
  <section class="min-h-screen bg-gray-200 page-documents">
    <div
      v-if="initializing"
      class="flex flex-col items-center justify-center h-screen"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <template v-else>
      <template v-if="loading || documents.total">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="documents.to === null || documents.to == documents.total"
            :finished-text="loading ? '加载中...' : '没有更多了'"
            error-text="加载失败，请下拉页面重新加载"
            :immediate-check="false"
            class="w-screen p-3"
            @load="fetch"
          >
            <div
              v-for="item of documents.data"
              :key="`item-${item._id}`"
              class="p-3 mb-3 bg-white border-b border-gray-200 rounded-lg list-card"
              @click="goLink(item)"
            >
              <div
                class="flex flex-col items-start justify-between flex-1 pl-2 content"
              >
                <div class="mb-2 font-semibold leading-snug">
                  {{ item.title
                  }}
                </div>
                <div
                  class="flex flex-row items-center justify-between w-full text-sm text-gray-400 "
                >
                  <span><van-icon name="bullhorn-o" /> {{ item.category }}</span>
                  <span><van-icon name="calendar-o" />
                    {{ $dayjs($route.params.collection == 'minutes' ? item.holdAt : item.createdAt).format('YYYY-MM-DD') }}</span>
                </div>
              </div>
            </div>
          </van-list>
          <div class="button-placeholder" />
        </van-pull-refresh>
      </template>
    </template>
  </section>
</template>

<script>
import share from '@/mixins/share'
import { mapState } from 'vuex'
export default {
  name: 'WorkspaceDocuments',
  mixins: [share],
  data() {
    return {
      mode: null,
      from: null,
      loading: true,
      initializing: true,
      refreshing: false,
      documents: {
        current_page: null,
        to: 0,
        total: 0,
        data: []
      },
      counts: {}
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapState('Common/Credential', ['userInfo'])
  },
  async mounted() {
    await Promise.all([this.fetch(), this.prepareWx()])
    this.disableShareMenu()
  },
  methods: {
    onRefresh() {
      this.refreshing = true
      this.fetch()
    },
    async fetch() {
      if (this.refreshing) {
        // eslint-disable-next-line no-console
        console.warn('这是在刷新页面')
        this.documents = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
        this.refreshing = false
      }
      let page = (this.documents.current_page ?? 0) + 1

      this.loading = true
      try {
        // eslint-disable-next-line no-console
        console.log('开始请求数据', page)
        const response = await this.tcb.callFunction({
          name: 'documents',
          data: {
            $url: 'list',
            collection: this.$route.params.collection,
            page: page
          }
        })
        // eslint-disable-next-line no-console
        console.log('函数请求返回', response)
        let { result } = response
        // eslint-disable-next-line no-console
        console.warn('总共有数据', page, response)
        let documents = {
          data: this.documents.data.concat(result.data.data),
          to: this.documents.to + result.data.data.length,
          current_page: page,
          total: result.data.total
        }
        // eslint-disable-next-line no-console
        console.log('数据加载完毕', documents)
        this.$set(this, 'documents', documents)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.$notify({ type: 'danger', message: '暂时无法获取文档信息' })
        this.documents = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
      }
      this.loading = false
      this.initializing = false
    },

    goLink(item) {
      this.$router.push({
        name: `workspace.documents.${this.$route.params.collection}`,
        params: { id: item._id, collection: this.collection }
      })
    }
  }
}
</script>

